.levelCard {
    margin: 0 4px;
}

.passShadowBg{
    background: linear-gradient(90deg, #0A0B0D 54.54%, rgba(10, 11, 13, 0) 101.75%);
}

.levelCardShadow { 
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 264px;
    background: linear-gradient(180deg, rgba(11, 16, 20, 0) 40.62%, #0B1014 90.38%);
}

.centered-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}