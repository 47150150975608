table th, table td {
    text-align: center;
    padding: 10px 15px;
    /* border: 1px solid red; */
}

td:first-child:hover, td:first-child:hover ~ td {
    border-bottom: 1px solid #1fe098 !important
}

tr {
    background: #1c252e;
    border-bottom: 1px solid #3b3b3b;
}

select, input[type=number] {
    background: none;
    color: white;
    outline: none;
    border: 1px solid #1fe098;
    text-align: center;
    width: 115px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
}

table select {
    cursor: pointer;
}